<template>
<div>
  <v-tooltip
    :bottom="bottom"
    :left="left"
    :open-delay="500"
    :right="right"
    :top="top"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="$emit('click')"
        :absolute="absolute"
        :bottom="positionBottom"
        :color="color"
        :disabled="disabled"
        :fab="fab"
        :fixed="fixed"
        :icon="icon"
        :left="positionLeft"
        :loading="loading"
        :readonly="readonly"
        :right="positionRight"
        :small="small"
        :top="positionTop"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ iconName }}</v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</div>
</template>

<script>
export default {
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    positionBottom: {
      type: Boolean,
      default: false,
    },
    positionLeft: {
      type: Boolean,
      default: false,
    },
    positionRight: {
      type: Boolean,
      default: false,
    },
    positionTop: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
}
</script>